import React from 'react';
import { OurInsightsReportsViewContext, TemplateProps } from '../../parser';
import { PathService } from '../../services';
import { OurInsightsReportsView } from '../../views';

const OurInsightsReports: React.FunctionComponent<TemplateProps<
  OurInsightsReportsViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OurInsightsReportsView posts={JSON.parse(pageContext.posts)} />;
};

export default OurInsightsReports;
